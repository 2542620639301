import React from 'react';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Layout from '../components/layout';

class IndexPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      timeout: false,
      article: '',
      loading: 'is-loading'
    };
  }

  componentDidMount() {
    this.timeoutId = setTimeout(() => {
      this.setState({ loading: '' });
    }, 100);
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  render() {
    return (
      <Layout location={this.props.location}>
        <div className={`body ${this.state.loading}`}>
          <div id="wrapper">
            <Header timeout={this.state.timeout} />
            <Footer timeout={this.state.timeout} />
          </div>
          <div id="bg"></div>
        </div>
      </Layout>
    );
  }
}

export default IndexPage;
