import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import pic from '../images/logo-white.png';

const Header = props => (
  <header id="header" style={props.timeout ? { display: 'none' } : {}}>
    <div>
      <img src={pic} alt="hytech" />
    </div>
    <div className="content">
      <div className="inner">
        <h1>HYTECH</h1>
        <p>Partnering with you to help you succeed</p>
      </div>
    </div>
    <nav>
      <ul>
        <li>
          <Link to="/services">
            <button>Services</button>
          </Link>
        </li>
        <li>
          <Link to="/about-us">
            <button>About Us</button>
          </Link>
        </li>
        <li>
          <Link to="/contact-us">
            <button>Contact Us</button>
          </Link>
        </li>
      </ul>
    </nav>
  </header>
);

Header.propTypes = {
  onOpenArticle: PropTypes.func,
  timeout: PropTypes.bool
};

export default Header;
